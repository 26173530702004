.git-push .git-push-block {
  margin-bottom: 25px;
}

.git-push p {
  padding: 10px 0;
  margin: 0;
}

.git-push pre {
  white-space: pre-wrap;
  word-wrap: normal;
  word-break: normal;
  display: block;
  padding: 12px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857;
  background: var(--msflm-white-color);
  border-radius: 10px;
  color: var(--msflm-text-muted-color)
}