.cursor-default {
  cursor: default;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-help {
  cursor: help;
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

.cursor-text {
  cursor: text;
}

.cursor-wait {
  cursor: wait;
}

.cursor-n-resize {
  cursor: n-resize;
}

.cursor-ne-resize {
  cursor: ne-resize;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-se-resize {
  cursor: se-resize;
}

.cursor-s-resize {
  cursor: s-resize;
}

.cursor-sw-resize {
  cursor: sw-resize;
}

.cursor-w-resize {
  cursor: w-resize;
}

.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor-not-allowed {
  cursor: not-allowed;
}