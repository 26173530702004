/*Text Color*/
.text-white {
  color: #ffffff !important;
}

.text-danger {
  color: #f62d51 !important;
}

.text-muted {
  color: #90a4ae !important;
}

.text-warning {
  color: #ffbc34 !important;
}

.text-success {
  color: #55ce63 !important;
}

.text-info {
  color: #009efb !important;
}

.text-inverse {
  color: #2f3d4a;
}

.text-blue {
  color: #02bec9;
}

.text-purple {
  color: #7460ee;
}

.text-primary {
  color: #7460ee !important;
}

.text-megna {
  color: #01c0c8;
}

.text-dark {
  color: #54667a;
}

.text-themecolor {
  color: #009efb;
}