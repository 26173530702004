.uploader-files .uploader-file-row {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box;
}

.uploader-files .uploader-filename {
  font-size: 14px;
  color: #555;
  max-width: 200px;
}

.uploader-files .progress-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.uploader-files .progress-bar {
  width: 100%;
  height: 8px;
  background-color: var(--msflm-blue-color-focus);
  border-radius: 5px;
}

.uploader-files .progress {
  background-color: var(--msflm-indigo-color);
  height: 100%;
  margin: 0;
  border-radius: 5px;
}

.uploader-files .progress-bar.success .progress {
  background-color: var(--msflm-success-color);
}

.uploader-files .progress-bar.failed {
  background-color: var(--msflm-danger-color);
}

.uploader-content .dropzone {
  margin: 0 auto;
  height: 140px;
  width: 140px;
  background-color: var(--msflm-bg-white-color);
  border: 2px dashed var(--msflm-border-gray-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.uploader-content .dropzone-highlight {
  background-color: var(--msflm-base-color);
}

.uploader-content .dropzone-icon {
  opacity: 0.3;
  margin-bottom: 10px;
}

.uploader-content .dropzone-file-input {
  display: none;
}