/*******************
 Breadcrumbs
*******************/

.breadcrumb {
  padding: 0;
  background: transparent;
}

.breadcrumb-item,
.breadcrumb-item a {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #55ce63;
}


.breadcrumb-item.active,
.breadcrumb-item.active a {
  color: #6c757d;
}

.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #a6b7bf;
  font-size: 14px;
}