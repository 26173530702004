/*******************
Buttons
******************/

button.btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: all !important;
}

.btn {
  padding: 7px 12px;
  font-size: 14px;
  cursor: pointer;
}

.btn-group label {
  color: #ffffff !important;
  margin-bottom: 0;
}

.btn-group label.btn-secondary {
  color: #54667a !important;
}

.btn-lg {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
}

.btn-circle.btn-sm {
  width: 35px;
  height: 35px;
  padding: 8px 10px;
  font-size: 14px;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 14px 15px;
  font-size: 18px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 14px 15px;
  font-size: 24px;
}

.btn-sm {
  padding: .25rem .5rem;
  font-size: 12px;
}

.btn-xs {
  padding: .25rem .5rem;
  font-size: 10px;
}

.button-list button, .button-list a {
  margin: 5px 12px 5px 0;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.btn-rounded {
  border-radius: 60px;
  padding: 7px 18px;
}

.btn-rounded.btn-lg {
  padding: .75rem 1.5rem;
}

.btn-rounded.btn-sm {
  padding: .25rem .5rem;
  font-size: 12px;
}

.btn-rounded.btn-xs {
  padding: .25rem .5rem;
  font-size: 10px;
}

.btn-secondary,
.btn-secondary.disabled,
.btn-secondary:disabled {
  background: #868e96 !important;
  border: 1px solid #868e96;
  color: #ffffff !important;
}

.btn-secondary:hover,
.btn-secondary.disabled:hover {
  background: #868e96;
  opacity: 0.7;
  border: 1px solid #868e96;
}

.btn-secondary.active, .btn-secondary:focus,
.btn-secondary.disabled.active,
.btn-secondary.disabled:focus {
  background: #868e96;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  background: #7460ee;
  border: 1px solid #7460ee;
}

.btn-primary:hover,
.btn-primary.disabled:hover {
  background: #7460ee;
  opacity: 0.7;
  border: 1px solid #7460ee;
}

.btn-primary.active, .btn-primary:focus,
.btn-primary.disabled.active,
.btn-primary.disabled:focus {
  background: #6352ce;
}

.btn-success,
.btn-success.disabled,
.btn-success:disabled {
  background: #55ce63;
  border: 1px solid #55ce63;
}

.btn-success:hover,
.btn-success.disabled:hover {
  background: #55ce63;
  opacity: 0.7;
  border: 1px solid #55ce63;
}

.btn-success.active, .btn-success:focus,
.btn-success.disabled.active,
.btn-success.disabled:focus {
  background: #4ab657;
}

.btn-info,
.btn-info.disabled,
.btn-info:disabled {
  background: #009efb;
  border: 1px solid #009efb;
}

.btn-info:hover,
.btn-info.disabled:hover {
  background: #009efb;
  opacity: 0.7;
  border: 1px solid #009efb;
}

.btn-info.active, .btn-info:focus,
.btn-info.disabled.active,
.btn-info.disabled:focus {
  background: #028ee1;
}

.btn-warning,
.btn-warning.disabled,
.btn-warning:disabled {
  background: #ffbc34;
  border: 1px solid #ffbc34;
  color: #ffffff;
}

.btn-warning:hover,
.btn-warning.disabled:hover {
  background: #ffbc34;
  color: #ffffff;
  opacity: 0.7;
  border: 1px solid #ffbc34;
}

.btn-warning.active, .btn-warning:focus,
.btn-warning.disabled.active,
.btn-warning.disabled:focus {
  background: #e9ab2e;
}

.btn-danger,
.btn-danger.disabled,
.btn-danger:disabled {
  background: #f62d51;
  border: 1px solid #f62d51;
}

.btn-danger:hover,
.btn-danger.disabled:hover {
  background: #f62d51;
  opacity: 0.7;
  border: 1px solid #f62d51;
}

.btn-danger.active, .btn-danger:focus,
.btn-danger.disabled.active,
.btn-danger.disabled:focus {
  background: #e6294b;
}

.btn-inverse,
.btn-inverse.disabled,
.btn-inverse:disabled {
  background: #2f3d4a;
  border: 1px solid #2f3d4a;
  color: #ffffff;
}

.btn-inverse:hover,
.btn-inverse.disabled:hover {
  background: #2f3d4a;
  opacity: 0.7;
  color: #ffffff;
  border: 1px solid #2f3d4a;
}

.btn-inverse.active, .btn-inverse:focus,
.btn-inverse.disabled.active,
.btn-inverse.disabled:focus {
  background: #232a37;
  color: #ffffff;
}

.btn-outline-default {
  background-color: #ffffff;
}

.btn-outline-default:hover,
.btn-outline-default:focus,
.btn-outline-default.focus {
  background: #f2f4f8;
}

.btn-outline-primary,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #7460ee;
  background-color: #ffffff;
  border-color: #7460ee;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  background: #7460ee;
  color: #ffffff;
  border-color: #7460ee;
}

.btn-outline-success,
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #55ce63;
  background-color: transparent;
  border-color: #55ce63;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success.focus {
  background: #55ce63;
  border-color: #55ce63;
  color: #ffffff;
}

.btn-outline-info,
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #009efb;
  background-color: transparent;
  border-color: #009efb;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info.focus {
  background: #009efb;
  border-color: #009efb;
  color: #ffffff;
}

.btn-outline-warning,
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffbc34;
  background-color: transparent;
  border-color: #ffbc34;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  background: #ffbc34;
  border-color: #ffbc34;
  color: #ffffff;
}

.btn-outline-danger,
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #f62d51;
  background-color: transparent;
  border-color: #f62d51;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  background: #f62d51;
  border-color: #f62d51;
  color: #ffffff;
}

.btn-outline-inverse,
.btn-outline-inverse.disabled,
.btn-outline-inverse:disabled {
  color: #2f3d4a;
  background-color: transparent;
  border-color: #2f3d4a;
}

.btn-outline-inverse:hover,
.btn-outline-inverse:focus,
.btn-outline-inverse.focus {
  background: #2f3d4a;
  border-color: #2f3d4a;
  color: #ffffff;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  background-color: #6352ce;
  border: 1px solid #6352ce;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
  background-color: #4ab657;
  border: 1px solid #4ab657;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
  background-color: #e6294b;
  border: 1px solid #e6294b;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
  background-color: #232a37;
  border: 1px solid #232a37;
}

.btn-label {
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  margin: -6px 12px -6px -14px;
  padding: 7px 15px;
}


