.lb-auth-page {
  /*background-image: url("/images/background/scheme.png");*/
  /*background-repeat: repeat-y;*/
  /*background-size: auto 100%;*/
  /*background-attachment: fixed;*/
  /*background-position: right;*/
}

@media (max-width: 992px) {
  .lb-auth-page {
    background-image: none;
  }

  .nav.lb-auth-footer .nav-item a {
    font-size: 12px;
  }
}