/*******************
Table Cell
*******************/
.table-box {
  display: table;
  width: 100%;
}

.table.no-border tbody td {
  border: 0;
}

.cell {
  display: table-cell;
  vertical-align: middle;
}

.table thead th, .table th {
  border: 0;
  font-weight: 600;
}

.table tbody td, .table td {
  font-weight: 400;
}

.table td, .table th {
  border-color: #f3f1f1;
}