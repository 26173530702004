/*Badge*/
.badge {
  font-weight: 400; }

.badge-xs {
  font-size: 9px; }

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px); }

.badge-success {
  background-color: #55ce63; }

.badge-info {
  background-color: #009efb; }

.badge-primary {
  background-color: #7460ee; }

.badge-warning {
  background-color: #ffbc34;
  color: #ffffff; }

.badge-danger {
  background-color: #f62d51; }

.badge-purple {
  background-color: #7460ee; }

.badge-red {
  background-color: #fb3a3a; }

.badge-inverse {
  background-color: #2f3d4a; }