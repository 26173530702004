.custom-file input,
.custom-file label {
  min-height: 38px;
}

.custom-file-label::after {
  background: #7460ee;
  border: 1px solid #7460ee;
  color: #FFFFFF;
  min-height: 36px;
  line-height: 24px;
  font-size: 14px;
}

.custom-file-label.disabled::after {
  opacity: .65;
}

.custom-file-label {
  font-size: 12px;
  line-height: 24px;
}