.card {
  background-color: var(--msflm-white-color);
  padding: 20px !important;
  min-width: 500px;
  border: 0;
}

.card .card-header {
  background: none;
}

.card .card-body {
}

.card .card-footer {
  background: none;
}