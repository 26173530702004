.input-file-container {
  position: relative;
  width: 225px;
}

.input-file-trigger {
  display: block;
  padding: 14px 45px;
  background: #39D2B4;
  color: #fff;
  font-size: 1em;
  transition: all .4s;
  cursor: pointer;
}

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 225px;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}

.input-file:hover + .input-file-trigger,
.input-file:focus + .input-file-trigger,
.input-file-trigger:hover,
.input-file-trigger:focus {
  background: #34495E;
  color: #39D2B4;
}

.file-return {
  margin: 0;
}

.file-return:not(:empty) {
  margin: 1em 0;
}

.file-return {
  font-size: .9em;
  font-style: normal;
  font-weight: normal;
}

.file-return > span {
  font-style: italic;
  font-size: .9em;
  font-weight: bold;
}

.txtcenter {
  margin-top: 4em;
  font-size: .9em;
  text-align: center;
  color: #aaa;
}

.copy {
  margin-top: 2em;
}

.copy a {
  text-decoration: none;
  color: #1ABC9C;
}


.uploader-file .progress-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.uploader-file .progress-bar {
  width: 100%;
  height: 8px;
  background-color: var(--msflm-blue-color-focus);
  border-radius: 5px;
}

.uploader-file .progress {
  background-color: var(--msflm-indigo-color);
  height: 100%;
  margin: 0;
  border-radius: 5px;
}

.uploader-file .progress-bar.success .progress {
  background-color: var(--msflm-success-color);
}

.uploader-file .progress-bar.failed {
  background-color: var(--msflm-danger-color);
}
