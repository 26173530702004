:root {
  /* =============================================================== */
  /* BASE COLOR ==================================================== */
  /* =============================================================== */
  --msflm-base-color: #FFFFFF;
  --msflm-blue-color: #007bff;
  --msflm-indigo-color: #6610f2;
  --msflm-purple-color: #6f42c1;
  --msflm-pink-color: #e83e8c;
  --msflm-red-color: #dc3545;
  --msflm-orange-color: #fd7e14;
  --msflm-yellow-color: #ffc107;
  --msflm-green-color: #28a745;
  --msflm-teal-color: #20c997;
  --msflm-cyan-color: #17a2b8;
  --msflm-white-color: #fff;
  --msflm-gray-color: #A6AFBC;
  --msflm-gray-dark-color: #343a40;
  --msflm-primary-color: #2684FF;
  --msflm-secondary-color: #6c757d;
  --msflm-success-color: #12C22E;
  --msflm-info-color: #17a2b8;
  --msflm-warning-color: #ffc107;
  --msflm-danger-color: #dc3545;
  --msflm-light-color: #f8f9fa;
  --msflm-skip-color: #dde6f3;
  --msflm-muted-color: #b5becb;
  --msflm-dark-color: #56637B;
  --msflm-black-color: #121416;

  /* =============================================================== */
  /* BASE FOCUS/HOVER COLOR ======================================== */
  /* =============================================================== */
  --msflm-base-color-focus: #f1f1f1;
  --msflm-blue-color-focus: #007bff;
  --msflm-indigo-color-focus: #6610f2;
  --msflm-purple-color-focus: #6f42c1;
  --msflm-pink-color-focus: #e83e8c;
  --msflm-red-color-focus: #dc3545;
  --msflm-orange-color-focus: #fd7e14;
  --msflm-yellow-color-focus: #ffc107;
  --msflm-green-color-focus: #28a745;
  --msflm-teal-color-focus: #20c997;
  --msflm-cyan-color-focus: #17a2b8;
  --msflm-white-color-focus: #fff;
  --msflm-gray-color-focus: #6c757d;
  --msflm-gray-dark-color-focus: #343a40;
  --msflm-primary-color-focus: #0056b3;
  --msflm-secondary-color-focus: #494f54;
  --msflm-success-color-focus: #19692c;
  --msflm-info-color-focus: #0f6674;
  --msflm-warning-color-focus: #ba8b00;
  --msflm-danger-color-focus: #a71d2a;
  --msflm-light-color-focus: #cbd3da;
  --msflm-muted-color-focus: #343a40;
  --msflm-dark-color-focus: #121416;
  --msflm-black-color-focus: #000000;

  /* =============================================================== */
  /* BACKGROUND COLOR ============================================== */
  /* =============================================================== */
  --msflm-bg-base-color: var(--msflm-base-color);
  --msflm-bg-white-color: var(--msflm-white-color);
  --msflm-bg-gray-color: var(--msflm-gray-color);
  --msflm-bg-blue-color: var(--msflm-primary-color);
  --msflm-bg-muted-color: var(--msflm-muted-color);
  --msflm-bg-gray-light-color: var(--msflm-muted-color);

  /* =============================================================== */
  /* TEXT COLOR ==================================================== */
  /* =============================================================== */
  --msflm-text-white-color: var(--msflm-white-color);
  --msflm-text-gray-color: var(--msflm-gray-color);
  --msflm-text-dark-color: var(--msflm-dark-color);
  --msflm-text-black-color: var(--msflm-black-color);
  --msflm-text-danger-color: var(--msflm-danger-color);
  --msflm-text-success-color: var(--msflm-success-color);

  /* =============================================================== */
  /* BORDER COLOR ================================================== */
  /* =============================================================== */
  --msflm-border-danger-color: var(--msflm-danger-color);
  --msflm-border-success-color: var(--msflm-success-color);
  --msflm-border-gray-color: var(--msflm-gray-color);
  --msflm-border-gray-dark-color: var(--msflm-dark-color);
}