.navbar {
  background: linear-gradient(to right, #318f94 0%, #5ec58c 100%);
  min-height: 70px;
  margin: 0 auto;
  padding: 10px 50px;
}


.navbar-light .navbar-nav .dropdown,
.navbar-light .navbar-nav .dropdown .dropdown-toggle.active,
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-item.active .nav-link {
  color: var(--msflm-text-white-color);
  font-size: 18px;
}

.navbar-light .navbar-nav .nav-item.active .nav-link {
  font-weight: 600;
}
